<template>
  <div
    id="reportTable"
    :class="{ home: home === true, takeOver: takeover == true }"
  >
    <table>
      <thead>
        <tr v-if="home === false">
          <template> </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="tablePick === 'Entries'">
          <Entries
            :data="data"
            :config="config"
            :home="home"
            :previousData="previousData"
            :title="title"
            :showGender="showGender"
            :Stableford="Stableford"
            :takeover="takeover"
            :report="report"
            :season="season"
            :code="code"
            :multi_tourns="multi_tourns"
          />
        </template>
        <template v-if="tablePick === 'Draw'">
          <Draw
            :data="data"
            :config="config"
            :home="home"
            :previousData="previousData"
            :title="title"
            :team="team"
            :match="match"
            :showGender="showGender"
            :Stableford="Stableford"
            :bbTeamEvent="data.bball_team"
            :takeover="takeover"
            :report="report"
            :season="season"
            :code="code"
          />
        </template>
        <template v-if="tablePick === 'Score' || tablePick === 'Live'">
          <template v-if="match == 'Y'">
            <MatchPlay
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="title"
              :config="config"
              :team="team"
              :match="match"
              :code="data.code"
              :report="report"
              :live="live"
              :season="data.season"
              :takeover="takeover"
              :bbTeamEvent="data.bball_team"
              :roundsplayed="data.rounds_played"
              :showGender="showGender"
              :Stableford="Stableford"
              :days_away="days_away"
              :live_scoring="live_scoring"
              :multi_tourns="multi_tourns"
            />
          </template>
          <template v-else>
            <Score
              :data="data"
              :previousData="previousData"
              :home="home"
              :title="title"
              :config="config"
              :team="team"
              :match="match"
              :code="data.code"
              :report="report"
              :live="live"
              :season="data.season"
              :takeover="takeover"
              :bbTeamEvent="data.bball_team"
              :roundsplayed="data.rounds_played"
              :showGender="showGender"
              :Stableford="Stableford"
              :days_away="days_away"
              :live_scoring="live_scoring"
              :multi_tourns="multi_tourns"
            />
          </template>
        </template>
        <template v-if="tablePick === 'Final'">
          <Results
            :data="data"
            :code="code"
            :season="season"
            :tmparams="tmparams"
            :previousData="previousData"
            :home="home"
            :title="title"
            :config="config"
            :team="team"
            :match="match"
            :report="report"
            :live="live"
            :takeover="takeover"
            :showGender="showGender"
            :Stableford="Stableford"
            :roundsplayed="data.rounds_played"
            :multi_tourns="multi_tourns"
          />
        </template>

        <template v-for="(tabledata, index) in Eds">
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              drawHome: home === true && tablePick === 'Draw'
            }"
          ></tr>
          <tr
            @click.stop="rowClicked(rowClicked === -1)"
            v-if="index === clickedRow"
            class="tr-kids"
            :key="index + Math.random()"
          >
            <td id="reportCard" colspan="11">
              <PlayerReportCard
                :data="playerCardData"
                :config="config"
                :actualRound="data.pft_round"
                :roundsPlayed="data.pft_round"
                :bbTeamEvent="data.bball_team"
                :Stableford="Stableford"
              />
            </td>
          </tr>
        </template>
        <!-- <template>
          <div class="LiveScoreMess" v-if="data.live_scoring_mess" :class="{suspendedRed : data.play_suspended == 'Y'}">
            <p class="LiveScoreMessPara">{{data.live_scoring_mess}}</p>
          </div>
        </template> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import PlayerReportCard from "@/ocs/reports/scores/scorecards/components/playerReportCard.vue";
import Entries from "@/ocs/reports/entries/components/entries.vue";
import Draw from "@/ocs/reports/draw/components/draw.vue";
import Score from "@/ocs/reports/scores/scoreboard/components/scoresOnly.vue";
import MatchPlay from "@/ocs/reports/scores/scoreboard/components/matchPlay.vue";
import Results from "@/ocs/reports/results/components/results.vue";
export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "bbTeamEvent",
    "showGender",
    "pastT",
    "tm",
    "head",
    "multi_tourns",
    "tmparams",
    "days_away",
    "live_scoring"
  ],
  components: { PlayerReportCard, Entries, Draw, Score, MatchPlay, Results },
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: []
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" || this.tablePick === "Live")
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.data.season +
              "/" +
              this.data.season +
              "-" +
              this.data.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => (this.playerCardData = response.data))
      );
    }
  },
  computed: {
    tablePick: function(picked) {
      if (this.title === "Tournament Entries") picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    }
  }
};
</script>

<style scoped>
/* Home Styles */
::v-deep .row.homeLeader.homewidthTakover {
  /* padding-bottom: 45px!important; */
}
.home.takeOver.MovePos {
  top: 1.9em!important;
  width: 360px;
}
::v-deep .home.takeOver {
  width: 345px;
  position: absolute;
  top: 0em !important;
  right: 0em !important;
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #055498;
}
td.homePlayername {
  color: #055498;
}
a.homePlayername {
  color: #055498;
}
a.homeResult {
  color: #055498 !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: #055498;
  border-top: 1px solid #055498 !important;
}
tr.drawHome {
  border-top: 0px solid #055498 !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #055498 !important;
}
tr.homeTd-size > td {
  font-size: 10pt;
  color: #055498;
}
h3.FullLeaderboard {
  font-size: 10pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 35%;
  padding-top: 25px;
}

.homeLeader {
  text-align: center !important;
  background-color: #03477b !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  padding-bottom: 10px !important;
}

.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  /* width: 300px; */
  /* position: absolute;
  top: 13px;
  right: 15%; */
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
p.LiveScoreMessPara {
  color: #fff;
  padding-top: 15px;
}
.LiveScoreMess {
  background: #01477b;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
.suspendedRed {
  background: red;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  margin: auto;
  left: 0;
  right: 0;
}
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px; 
}
.title {
  text-align: center;
  background-color: #01477b;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #01477b;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #015699;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #42a7c6;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: #e6f4ff !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: #e6f4ff;
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003; 
  color: white;
}
@media only screen and (max-width: 1200px) {
  .home {
    /* width: 100%;
    position: relative;
    top: 0px !important;
    right: 0%; */
  }
  .row.pastbtnRow {
    margin-top: -22px;
    margin-top: 0px !important;
    margin-left: 0px !important;
    width: auto !important;
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .Mob {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 480px) {
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>
