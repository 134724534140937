var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[(this.$route.name === 'home')?[_c('b-col',[_c('b-container',{staticClass:"home"},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                _vm.videos[0].snippet.resourceId.videoId,"allowfullscreen":""}})],1)],1)]:(this.$route.query.id === '')?_c('b-row',_vm._l((_vm.videos),function(vids,index){return _c('b-col',{key:index,attrs:{"lg":"4"}},[_c('b-nav-item',{attrs:{"to":{
            name: 'videos',
            query: { id: vids.id },
          }}},[_c('b-card',{staticClass:"feat",attrs:{"title":vids.snippet.title,"img-src":vids.snippet.thumbnails.high.url,"img-alt":"Image","img-top":"","tag":"article"}}),_c('b-img',{staticClass:"play",attrs:{"src":"https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"}})],1)],1)}),1):_c('b-row',[(this.$route.name === 'team-europe')?_vm._l((_vm.videos.slice(0, 6)),function(vids,index){return _c('b-col',{key:index,attrs:{"lg":"4"}},[_c('div',{on:{"click":function($event){return _vm.$bvModal.show(vids.etag)}}},[_c('b-card',{staticClass:"feat TeamEurope",attrs:{"title":vids.snippet.title.toUpperCase(),"img-src":vids.snippet.thumbnails.high.url,"img-alt":"Image","img-top":"","tag":"article"}},[_c('b-img',{staticClass:"play",attrs:{"src":"https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"}}),_c('b-modal',{attrs:{"id":vids.etag,"hide-header":"","centered":"","hide-footer":""}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                      vids.snippet.resourceId.videoId,"allowfullscreen":""}})],1)],1)],1)])}):_vm._l((_vm.videos),function(vids,index){return _c('b-col',{key:index,staticClass:"paddingYoutube",attrs:{"lg":"4"}},[(vids.snippet.title === 'Private video')?_c('span'):_c('div',{on:{"click":function($event){return _vm.$bvModal.show(vids.etag)}}},[_c('b-card',{staticClass:"feat",attrs:{"title":vids.snippet.title,"img-src":vids.snippet.thumbnails.high.url,"img-alt":"Image","img-top":"","tag":"article"}},[_c('b-img',{staticClass:"play",attrs:{"src":"https://ocs-aus.sgp1.cdn.digitaloceanspaces.com/pga-aus/2020/02/play.png"}}),_c('b-modal',{attrs:{"id":vids.etag,"hide-header":"","centered":"","hide-footer":""}},[_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":'https://www.youtube.com/embed/' +
                      vids.snippet.resourceId.videoId,"allowfullscreen":""}})],1)],1)],1)])})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }