<template>
  <div>
    <Ads
      class="mobHide"
      :page="'report'"
      :adNum="'top'"
      :mob="false"
      :side="false"
    />

    <div class="top">
      <b-container class="selectRD">
        <b-row class="yellow">
          <b-col lg="7" class="noPad">
            <div class="over">
              <b-img
                v-if="tmparams.course_image_file_1 !== ''"
                class="courseImg"
                :src="
                  config.VUE_APP_PLAYER_IMAGE +
                    'courses/' +
                    this.$route.query.id +
                    '/' +
                    tmparams.course_code +
                    '/' +
                    tmparams.course_image_file_1
                "
              ></b-img>
              <b-img
                v-else
                class="courseImg"
                :src="
                  'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/sliderdummycourse.png'
                "
              ></b-img>
            </div>
          </b-col>
          <b-col class="tourInfo" lg="5">
            <template v-if="data.event_sponsor_logo.length">
              <b-img class="courseLogo" :src="data.event_sponsor_logo"></b-img>
            </template>
            <template v-else>
              <h3 class="coursenameFull">{{ data.full_name }}</h3>
            </template>
            <h3 class="tourDate">
              {{ data.tourn_dates }}
            </h3>
            <h3 class="tourCourse">
              {{ courseName }}
            </h3>
          </b-col>
        </b-row>
        <b-row class="selRep">
          <b-col>
            <div :class="drop">
              <b-nav card-header pills>
                <b-dropdown
                  v-if="Array.isArray(tmparams.reports.reports_entry)"
                  :text="title"
                  class="dropdownReport"
                >
                  <template v-if="data.bball_team == 'Y'">
                    <b-dropdown-item
                      v-on:click="changeReport"
                      v-for="(reports, index) in filterExemptions"
                      :key="index"
                      :title="reports.report_url"
                      :value="reports.report_title"
                      v-show="
                        reports.report_title.indexOf('Net') != -1 ||
                          reports.report_title.includes('Draw') ||
                          reports.report_title.includes('Entries') ||
                          reports.report_title.includes('Result')
                      "
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </template>
                  <template v-else>
                    <b-dropdown-item
                      v-on:click="changeReport"
                      v-for="(reports, index) in filterExemptions"
                      :key="index"
                      :title="reports.report_url"
                      :value="reports.report_title"
                      >{{ reports.report_title }}</b-dropdown-item
                    >
                  </template>
                </b-dropdown>
                <!-- <b-dropdown v-else text="Please Select a Report">
                  <b-dropdown-item
                    v-on:click="changeReport"
                    v-for="(reports, index) in tmparams.reports"
                    :key="index"
                    :title="reports.report_url"
                    :value="reports.report_title"
                    v-hide="reports.report_title == 'Scoring Statistics' || reports.report_title == 'Statistics Tables' || reports.report_title == 'Live Scoring Monitor' || reports.report_title == 'Tournament Summary' || reports.report_title == 'Course Statistics' || reports.report_title.indexOf('Gross') != -1"
                    >{{ reports.report_title }}</b-dropdown-item
                  >
                </b-dropdown> -->
              </b-nav>
            </div>
          </b-col>
          <b-col class="col-md-3 multi hideMobNew"> </b-col>
          <!-- <b-col lg='4'>
            <Ads class='mobHide' :page="'report'" :adNum="'small'" :mob='false' :side='true'/>
          </b-col> -->
        </b-row>
        <div>
          <Ads
            class="mobHide"
            :page="'report'"
            :adNum="'small'"
            :mob="false"
            :side="true"
          />
        </div>
        <b-row v-if="tmparams.multi_course === 'Y'">
          <b-col lg="8">
            <div class="multi">
              <span class="course">Courses:</span>

              <span
                class="multiList"
                v-for="(multi, index) in tmparams.courses.courses_entry"
                :key="index"
              >
                <span :class="multi.course_X_colour">•</span
                ><span class="multiName">
                  <span class="nameMulti" :class="multi.course_X_colour">
                    = {{ multi.course_X_id }}</span
                  ></span
                >
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="mid">
      <b-container>
        <div class="reports">
          <div
            class="mainNonScores"
            v-if="
              this.currentReport.indexOf('tmentry') !== -1 ||
                this.currentReport.indexOf('tmdraw') !== -1 ||
                this.currentReport.indexOf('tmresult') !== -1 ||
                this.currentReport.indexOf('tmtsum') !== -1 ||
                this.currentReport.indexOf('tmoomt') !== -1
            "
          >
            <ReportTable
              :data="data"
              :tmparams="tmparams"
              :code="data.code"
              :season="data.season"
              :home="home"
              :previousData="previousData"
              :title="title"
              :config="config"
              :team="teamEvent"
              :match="tmparams.match_play"
              :bbTeamEvent="tmparams.bball_team"
              :showGender="showGender"
              :Stableford="data.stableford"
              :roundsP="roundsP"
            />
          </div>
          <div
            class="main"
            v-else-if="this.currentReport.indexOf('tmscore') !== -1"
          >
            <template v-if="data.match_play == 'Y'">
              <ReportTable
                :data="data"
                :previousData="previousData"
                :home="home"
                :title="title"
                :config="config"
                :team="teamEvent"
                :match="tmparams.match_play"
                :bbTeamEvent="tmparams.bball_team"
                :RTCDS="tmparams.live_oom"
                :code="data.code"
                :season="data.season"
                :showGender="showGender"
                :Stableford="Stableford"
              />
            </template>
            <template v-else>
              <b-tabs content-class="mt-3">
                <b-tab
                  title="Leaderboard"
                  active
                  title-item-class="hideLeadertab"
                >
                  <ReportTable
                    :data="data"
                    :previousData="previousData"
                    :home="home"
                    :title="title"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparams.match_play"
                    :bbTeamEvent="tmparams.bball_team"
                    :RTCDS="tmparams.live_oom"
                    :code="data.code"
                    :season="data.season"
                    :showGender="showGender"
                    :Stableford="Stableford"
                  />
                </b-tab>
                <b-tab title="Hole-by-Hole" title-item-class="hideHBH">
                  <HBH
                    :tabs="tabs"
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                    :team="teamEvent"
                    :bbTeamEvent="tmparams.bball_team"
                    :showGender="showGender"
                    :Stableford="Stableford"
                  />
                </b-tab>
              </b-tabs>
            </template>
          </div>
          <div
            class="main"
            v-else-if="this.currentReport.indexOf('tmlsmon') !== -1"
          >
            <template v-if="data.match_play == 'Y'">
              <ReportTable
                :data="data"
                :previousData="previousData"
                :home="home"
                :title="title"
                :config="config"
                :team="teamEvent"
                :match="tmparams.match_play"
                :RTCDS="tmparams.live_oom"
                :bbTeamEvent="tmparams.bball_team"
                :code="data.code"
                :season="data.season"
                :showGender="showGender"
                :Stableford="Stableford"
              />
            </template>
            <template v-else>
              <b-tabs content-class="mt-3 matt">
                <b-tab
                  title="Live Scoring"
                  active
                  title-item-class="hideLeadertab"
                >
                  <ReportTable
                    :data="data"
                    :previousData="previousData"
                    :home="home"
                    :title="title"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparams.match_play"
                    :RTCDS="tmparams.live_oom"
                    :bbTeamEvent="tmparams.bball_team"
                    :code="data.code"
                    :season="data.season"
                    :showGender="showGender"
                    :Stableford="Stableford"
                  />
                </b-tab>
                <b-tab
                  title="Hole by Hole"
                  class="hbhmob"
                  title-item-class="hideHBH"
                >
                  <HBH
                    :tabs="tabs"
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                    :team="teamEvent"
                    :bbTeamEvent="tmparams.bball_team"
                    :showGender="showGender"
                    :Stableford="Stableford"
                  />
                </b-tab>
              </b-tabs>
            </template>
          </div>
        </div>
      </b-container>
    </div>
    <div
      :class="{ colorMess: data.play_suspended == 'Y' }"
      class="livemessage"
      v-if="livemessage === 'Y' && livemessageText.length"
    >
      <span v-if="data.play_suspended == 'Y' && livemessage == 'N'"
        >Play Suspended</span
      >
      <span v-else>{{ livemessageText }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";
import HBH from "@/ocs/reports/scores/hole-by-hole/components/hbh.vue";
import Ads from "@/components/ads.vue";

export default {
  name: "Reports",
  components: {
    ReportTable,
    HBH,
    Ads
  },
  props: ["config", "showGender", "Stableford"],
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      home: false,
      tabs: true,
      title: this.$route.query.title
    };
  },
  methods: {
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then(response => (this.data = response.data))
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.tmparams = response.data;
        });
    }
  },
  computed: {
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
        // !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    bballTeamEvent: function(bballlTeam) {
      // var array = this.data
      var data = this.data;
      if (data.bball_team == "Y") bballlTeam = "Y";
      else bballlTeam = "N";
      return bballlTeam;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          "https://api.euro.ocs-software.com/let/cache/qsc/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              'https://api.euro.ocs-software.com/let/cache/qsc/' +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              'https://api.euro.ocs-software.com/let/cache/qsc/' +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              'https://api.euro.ocs-software.com/let/cache/qsc/' +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            'https://api.euro.ocs-software.com/let/cache/qsc/' +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            'https://api.euro.ocs-software.com/let/cache/qsc/' +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            'https://api.euro.ocs-software.com/let/cache/qsc/' +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios
      .get(this.reportTitle)
      .then(response => {
        this.data = response.data;
        return axios.get(
          "https://api.euro.ocs-software.com/let/cache/qsc/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-tmticx.json?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.tmparams = response.data;
        this.showGender = response.data.show_gender;
        this.Stableford = response.data.stableford;
        this.livemessage = response.data.live_message;
        this.livemessageText = response.data.live_scoring_mess;
        this.courseName = response.data.course_name;
        this.roundsP = response.rounds_played;
      });
  }
};
</script>

<style scoped>
h3.coursenameFull {
  color: #03477a;
  text-align: center;
  margin-top: 2em;
}
::v-deep .nav-tabs .nav-link {
  border-radius: 0;
  font-weight: 400;
  font-size: 12pt;
  color: #ffffff !important;
  background: #015699;
}
span.nameMulti {
  font-size: 13pt !important;
  margin-top: -4px;
}
span.multiName {
  font-size: 13pt !important;
  font-weight: 400;
}
.course {
  font-size: 13pt;
  font-weight: 400;
}
.colorMess {
  background: red;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.livemessage {
  background: #01477b;
  color: white;
  font-weight: 500;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.dropdownReport {
  /* margin-left: 10px; */
}
.main {
  /* padding-top: 4%; */
  padding-top: 0%;
  padding-bottom: 2%;
}
.mainNonScores {
  /* padding-top: 6%; */
  padding-top: 0%;
  padding-bottom: 2%;
}

.multi {
  font-size: 13px !important;
  line-height: 50px;
  color: #0b3f7e;
  padding-bottom: 15px;
}
.B {
  color: #333333 !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
::v-deep .R {
  color: #bb0000 !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
.C {
  color: #0f61ce !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
.G {
  color: #0b3f7e !important;
  font-size: 40px !important;
  line-height: 50px !important;
  vertical-align: bottom;
  font-size: 60px !important;
  line-height: 0px !important;
  vertical-align: middle;
  margin-top: -12px;
  display: inline-block;
}
::v-deep .nav-link.active {
  color: white !important;
  background-color: #01477b !important;
  width: 100%;
  border-color: #01477b !important;
  height: 100%;
  font-weight: 400;
  font-size: 12pt;
}
.selectRD {
  padding: 2% 1%;
}
.selRep {
  /* padding: 100px 0 50px; */
  /* padding: 100px 0 20px; */
  padding: 50px 0 0px;
  padding-left: 8px;
}
.dropdown {
  /* width: 25%; */
  width: 300px;
}
::v-deep .dropdown-toggle {
  text-align: left;
  background-color: #015699;
  border-radius: 0;
}
::v-deep .dropdown-toggle::after {
  margin-top: 3%;
  float: right;
}
::v-deep .mt-3 {
  margin-top: 0 !important;
}

.top {
  padding-top: 50px;
}

.yellow {
  background-color: #eeb500;
  margin: 0px;
}
.noPad {
  padding: 0;
}
.tourDate {
  color: #ffffff;
  font-weight: 400;
  font-size: 16pt;
  margin-top: 15px;
  margin-bottom: 10px;
}
.tourCourse {
  color: #ffffff;
  font-weight: 400;
  font-size: 15pt;
  color: #ffffff;
  margin-bottom: 50px;
}
.tourInfo {
  margin: auto;
  text-align: center;
}
.over {
  overflow: hidden;
  height: 100%;
}
.courseImg {
  width: 100%;
  height: 100%;
}
.courseLogo {
  max-width: 183px;
  margin-top: 70px;
  width: 140px;
  max-height: 80px;
}
::v-deep .nav-tabs .nav-link {
  border-radius: 0;
  font-weight: 400;
  font-size: 12pt;
  color: #03477a;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
::v-deep li.nav-item {
  border-top: 1px solid #03477a;
  border-left: 1px solid #03477a;
  border-right: 1px solid #03477a;
  margin-right: 5px;
}
@media only screen and (max-width: 990px) {
  ::v-deep .selRep {
    /* padding: 100px 0 50px; */
    /* padding: 100px 0 20px; */
    padding: 50px 0 0px;
    padding-left: 0;
  }
  ::v-deep .hideHBH {
    display: none;
  }
  ::v-deep .hideLeadertab {
    display: none;
  }
  ::v-deep .courseLogo {
    max-width: 183px;
    margin-top: 10px;
    width: 100px;
    max-height: 80px;
  }
  .tourDate {
    color: #ffffff;
    font-weight: 400;
    font-size: 12pt;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .tourCourse {
    color: #ffffff;
    font-weight: 400;
    font-size: 9pt;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .dropdown {
    width: 100%;
  }
  .hideMobNew {
    display: none;
  }
  ::v-deep .dropdown-toggle::after {
    margin-top: 1.5%;
    float: right;
  }
  ::v-deep .card-header-pills {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  ::v-deep .selRep {
    /* padding: 100px 0 50px; */
    /* padding: 100px 0 20px; */
    padding: 50px 0 0px;
    padding-left: 0;
    margin-top: 50px !important;
  }
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
  .drop {
    margin-top: -10%;
  }
  .selectRD {
    padding: 2% 4%;
  }
  ::v-deep .top {
    padding-top: 0px !important;
  }
  ::v-deep .selRep {
    /* padding: 100px 0 50px; */
    /* padding: 100px 0 20px; */
    padding: 50px 0 0px;
    padding-left: 0;
  }
}
</style>
