<template>
  <div>
    <div id="oomPage">
      <Ads
        class="mobHide"
        :page="'rank'"
        :adNum="'rtcds'"
        :mob="false"
        :side="false"
        :white="true"
      />
      <Ads
        class="mobShow"
        :page="'rank'"
        :adNum="'rtcds'"
        :mob="true"
        :side="false"
        :white="true"
      />
      <div>
        <div class="Splashheight">
          <template v-if="om === 'PT'">
            <b-img
              class="splash"
              :src="wpPageBanner.acf['banner-image']"
            ></b-img>
          </template>
          <template v-else-if="om === 'WR'">
            <b-img
              class="splash"
              :src="bannerImage['banner-image']"
            ></b-img>
          </template>
          <template v-else>
            <b-img
              class="splash"
              :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/01/oom-1.png'"
            ></b-img>
          </template>
        </div>
        <div
          v-if="
            om === 'PT' ||
              om === 'RK' ||
              om === 'S1' ||
              om === 'OM' ||
              om === 'SC' ||
              om === 'SD'
          "
          class="slider mobHide"
        >
          <b-container>
            <b-row>
              <b-col>
                <OomSlider
                  :oomKey="om"
                  :oom="currentOom"
                  :season="Year"
                  :config="config"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <template v-if="om === 'WR'">
      <b-container class="topSec">
        <div class="rolexTop">
          <b-row class="threeDropdown">
            <b-col>
              <div v-if="cTab === 'Rankings'"
                class="schedule-dropdown year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
              >
                <b-form-select  
                  v-model="Year" 
                  v-on:change="changeYear(Year)"
                >
                  <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value,
                    index) in seasons.seasons.seasons_entry.slice().reverse()"
                    :key="index"
                    :value="value.code"
                  >{{value.desc}}</b-form-select-option>
                </b-form-select>
              </div>
            </b-col>
          </b-row>
          <!-- <span v-if="om === 'WR'">
            <b-nav-item class="viewFull">VIEW FULL Rankings</b-nav-item>
          </span> -->
        </div>
        <span v-if="cTab === 'Rankings'" title="">
          <!-- <b-img
            v-if="om === 'PT' && Year >= 2020"
            class="costaImg"
            :src="
              config.VUE_APP_WP_DIGITAL_OCEAN +
                '2021/03/LET20-LOGO-Race-to-Costa-del-Sol-Official-Rankings-Full-Color.png'
            "
          /> -->
          <h2 class="racetocosta-text" v-if="om === 'PT' && Year >= 2020">
            The Order of Merit is a season-long competition to crown the Ladies European Tour's number one player.
          </h2>
        </span>
      </b-container>
      </template>
      <template v-else>
      <b-container class="topSec">
        <div>
          <b-row class="threeDropdown">
            <b-col>
              <!-- <b-dropdown
                v-if="cTab === 'Rankings'"
                class="year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
                :text="currentOom.full_name"
              >
                <b-dropdown-item
                  v-on:click="pickStat"
                  v-for="(stat, index) in statCodes"
                  :key="index"
                  :title="stat.code"
                  >{{ stat.full_name }}</b-dropdown-item
                >
              </b-dropdown> -->
              <!-- <template v-if="om == 'SD' || om == 'SC'">

              </template> -->
              <template>
                <div v-if="cTab === 'Rankings'"
                  class="schedule-dropdown year"
                  :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
                >
                  <b-form-select  
                    v-model="om" 
                    v-on:change="pickStat(om)"
                  >
                    <b-form-select-option 
                      class="opt-arrow"
                      v-for="(value,
                      index) in statCodes"
                      :key="index"
                      :value="value.code"
                    >{{value.full_name}}</b-form-select-option>
                  </b-form-select>
                </div>
              </template>
            </b-col>
            <b-col>
              <template>
              <div v-if="cTab === 'Rankings'"
                class="schedule-dropdown year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
              >
                <b-form-select  
                  v-model="Year" 
                  v-on:change="changeYear(Year)"
                >
                  <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value,
                    index) in seasons.seasons.seasons_entry.slice().reverse()"
                    :key="index"
                    :value="value.code"
                  >{{value.desc}}</b-form-select-option>
                </b-form-select>
              </div>
              </template>
            </b-col>
            <b-col>
              <div 
                class="schedule-dropdown year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
              >
                <b-form-select  
                  v-model="cTab" 
                  v-on:change="changePage(cTab)"
                >
                  <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value,
                    index) in tabThree"
                    :key="index"
                    :value="value.title"
                  >{{value.title}}</b-form-select-option>
                </b-form-select>
              </div>
            </b-col>
          </b-row>
          <!-- <span v-if="om === 'WR'">
            <b-nav-item class="viewFull">VIEW FULL Rankings</b-nav-item>
          </span> -->
        </div>
        <span v-if="cTab === 'Rankings'">
          <!-- <b-img
            v-if="om === 'PT' && Year >= 2020"
            class="costaImg"
            :src="
              config.VUE_APP_WP_DIGITAL_OCEAN +
                '2021/03/LET20-LOGO-Race-to-Costa-del-Sol-Official-Rankings-Full-Color.png'
            "
          /> -->
          <h2 class="racetocosta-text" v-if="om === 'PT' && Year >= 2020">
            The Order of Merit is a season-long competition to crown the Ladies European Tour's number one player.
          </h2>
        </span>
      </b-container>
      </template>



      <b-container v-if="cTab === 'Rankings'">
        <div class="main" :class="{ PT: this.om === 'PT' && Year >= 2020 }" v-if="noData == 'N'">
          <h3>
            {{ currentOom.full_name }}
            <span class="following-text">{{
              currentOom.last_tournament
            }}</span>
          </h3>
        </div>
        <template v-if="currentOom !== null && noData == 'N'">
          <table class="tableWidth">
            <thead class="nonPT" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Nat</th>
                <template v-if="om == 'WR'">
                  <th>Avg.Pts</th>
                  <th>Tot.Pts</th>
                </template>
                <template v-else>
                  <th>{{value_2_head}}</th>
                </template>
                <th>Played</th>
                <th class="hideMob"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oom, index) in currentOom.oom.oom_entry" :key="index">
                <td>{{oom.pos}}</td>
                <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank" class="oomName" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
                      <td class="oomName">{{oom.name}}</td>
                    </a>
                </template>
                <template v-else>
                  <td>{{oom.name}}</td>
                </template>
                <td>
                  <span>
                    <img
                      :class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + oom.nationality)
                          | lowercase
                      "
                    />
                  </span>
                </td>
                <template v-if="om == 'WR'">
                  <td>{{oom.oom_value[1]}}</td>
                  <td>{{oom.oom_value[0]}}</td>
                </template>
                <template v-else>
                  <td>{{oom.oom_value}}</td>
                </template>
                <td>{{oom.tournaments_played}}</td>
                <td class="hideMob">
                  <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank">
                      <span class="iconRight">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else-if="previousOom !== null && noData == 'N'">
          <table class="tableWidth">
            <thead class="nonPT" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Nat</th>
                <template v-if="om == 'WR'">
                  <th>Avg.Pts</th>
                  <th>Tot.Pts</th>
                </template>
                <template v-else>
                  <th>{{value_2_head}}</th>
                </template>
                <th>Played</th>
                <th class="hideMob"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oom, index) in previousOom.oom.oom_entry" :key="index">
                <td>{{oom.pos}}</td>
                <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank" class="oomName" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
                      <td class="oomName">{{oom.name}}</td>
                    </a>
                </template>
                <template v-else>
                  <td>{{oom.name}}</td>
                </template>
                <td>
                  <span>
                    <img
                      :class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + oom.nationality)
                          | lowercase
                      "
                    />
                  </span>
                </td>
                <template v-if="om == 'WR'">
                  <td>{{oom.oom_value[1]}}</td>
                  <td>{{oom.oom_value[0]}}</td>
                </template>
                <template v-else>
                  <td>{{oom.oom_value}}</td>
                </template>
                <td>{{oom.tournaments_played}}</td>
                <td class="hideMob">
                  <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank">
                      <span class="iconRight">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <h2 class="noDataAvailable">No data Available at this time</h2>
        </template>
      </b-container>
      <b-container v-if="cTab === 'News'">
        <h2 class="rtcds-title">ORDER OF MERIT News</h2>
        <b-row>
          <b-col
            lg="4"
            id="mobilePost"
            v-for="(posts, index) in News"
            :key="index"
          >
            <b-nav-item :to="'/blog/' + posts.slug">
              <div class="outerImg">
                <b-img
                  :class="postImg"
                  :src="posts.better_featured_image.source_url"
                  fluid
                  alt="Responsive image"
                />
              </div>
              <div class="miniWrap">
                <h3 v-html="posts.title.rendered" class="homeNews"></h3>
              </div>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-if="cTab === 'About'">
        <!-- <span v-html='About.title.rendered'></span> -->
        <h2 class="rtcds-title">WHAT IS THE ORDER OF MERIT?</h2>
        <span v-html="About.content.rendered"></span>
      </b-container>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import OomSlider from "@/components/oomSlider.vue";
import Ads from "@/components/ads.vue";
// import TeamEurope from './teamEurope.vue';

export default {
  name: "oom",
  components: { OomSlider, Ads },
  props: ["config"],
  data() {
    return {
      currentOom: [],
      previousOom: [],
      seasons: [],
      Year: this.$route.query.id,
      year: parseInt(this.$route.query.id) - 1,
      flag: "flag",
      id: parseInt(this.$route.query.id) - 1,
      om: this.$route.query.oom,
      PT: "PT",
      wpPage: [],
      cTab: "Rankings",
      statCodes: [],
      rolex: { value: "WR", title: "Rolex Ranking" },
      tabThree: [
        { 
          title: "Rankings",
          cTab: "Rankings"
        }, 
        { 
          title: "News",
          cTab: "News"
        }, 
        { 
          title: "About",
          cTab: "About"        }
        ],
      News: [],
      About: [],
      value_2_head: '',
      noData: '',
      bannerImage: '',
      wpPageBanner: ''
    };
  },
  computed: {
    currentStat: function() {
      return this.statCodes.filter(om => !om.code.indexOf(this.om));
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    changePage: function(cTab) {
      if (cTab === "News")
        return (
          (this.cTab = cTab),
          axios
            .get(
              process.env.VUE_APP_WPAPI_URL +
                "wp/v2/posts?categories=249&per_page=20"
            )
            .then(response => (this.News = response.data))
        );
      else if (cTab === "RANKING") 
        return (this.cTab = cTab);
      else if (cTab === "About")
        return (
          (this.cTab = cTab),
          axios
            .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/168421")
            .then(response => (this.About = response.data))
        );
    },
    pickStat: function(om) {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.Year +
              "/" +
              this.Year +
              "-ooms-oom-" +
              om +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => (
            this.currentOom = response.data,
            console.log('name'),
            console.log(response.data.oom_description),
            this.value_2_head = response.data.value_2_head
          ))
      );
    },
    changeYear: function() {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.Year +
              "/" +
              this.Year +
              "-ooms-oom-" +
              this.om +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => {
            if (Object.keys(response.data).length) {
              this.currentOom = response.data,
              console.log("Data is Available")
              this.noData = 'N'
            } else {
              this.noData = 'Y'
              console.log("Data is Not Available")
            }
            this.value_2_head = response.data.value_2_head
          })
      );
    }
  },

  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.Year +
          "/" +
          this.Year +
          "-ooms-oom-" +
          this.om +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        if (Object.keys(response.data).length) {
          this.currentOom = response.data,
          console.log("Data is Available")
          this.noData = 'N'
        } else {
          this.noData = 'Y'
          console.log("Data is Not Available")
        }
        this.value_2_head = response.data.value_2_head;
        console.log("this.value_2_head")
        console.log(this.value_2_head)
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.year +
            "/" +
            this.year +
            "-ooms-oom-" +
            this.om +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.previousOom = response.data;
        this.value_2_head = response.data.value_2_head;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
          this.Year + '/' +
          "tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
          new Date().getTime()
        );
      })
      .then(response => {
        this.statCodes = response.data.ooms.ooms_entry;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            "tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.seasons = response.data;
        return axios.get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/62017?randomadd=" +
            new Date().getTime());
      })
      .then(response => {
        this.wpPage = response.data;
        this.bannerImage = response.data.acf;
        return axios.get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/357?randomadd=" +
            new Date().getTime());
      })
      .then(response => {
        this.wpPageBanner = response.data;
        console.log("this.wpPageBanner")
        console.log(this.wpPageBanner)
      })
  }
};
</script>

<style scoped>
h2.noDataAvailable {
  font-size: 2rem;
  color: #3c974c;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
::v-deep .schedule-dropdown.year.PTB > select {
  background-color: #015699;
}
::v-deep .schedule-dropdown.year > select {
  background-color: #015699;
}
::v-deep .statsCode.year.PTB > select {
  background-color: #3d974c;
}
::v-deep .sstatsCode.year > select {
  background-color: #015699;
}
::v-deep .custom-select {
  width: 300px;
  -webkit-appearance: none;
  /* background-color: #015699; */
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  top: -48px;
  position: relative;
  left: -20px;
  text-align: left;
  height: 42px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
}
::v-deep .PTCOLOR > tr > th {
  /* background: #fff;
  color: #000!important; */
  border-bottom: 2px solid #dee2e6;
}
.PTCOLOR {
  /* color: #3d974c!important;  */
}
.tableWidth {
  width:100%;
}
 td.statsLabel > a:hover {
  text-decoration: none;
  color: #035699;
}
::v-deep .iconRight:hover {
  color: #035699;
}
::v-deep span.iconRight {
  color: black;
}
::v-deep  a.oomName {
  color: #000;
}
a.oomName:hover {
  text-decoration: none;
  color: #035699;
}
td.NameRank > a:hover {
  color: #035699;
  font-weight: 400;
  text-decoration: none;
}
::v-deep tr:hover {
  background-color: #f6f5f5 !important;
  cursor: pointer;
}
div#mobilePost {
  padding: 0;
}
.rtcds-title {
  color: #3d974c;
  font-size: 30pt;
  margin-bottom: 30px;
}
.PTCOLOR {
}
.main.PT > h3 {
  /* background: #3c974c; */
}
::v-deep th.statsLabel.PTCOLOR {
  background: white;
}
::v-deep thead.PT > tr {
  background: white !important;
}
td.NameRank > a {
  color: #212529;
  font-weight: 400;
}
::v-deep thead > tr {
  background-color: #42a7c6 !important;
  color: #fff;
}
::v-deep .table th,
.table td {
  border-top: 0px solid #dee2e6 !important;
}
::v-deep thead.PT > tr {
  background-color: #fff !important;
  color: #212529;
}
::v-deep thead.nonPT > tr {
  background-color: #42a7c6 !important;
  color: #fff;
}
::v-deep tr.rtcds:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
::v-deep th.statsLabel {
  font-weight: 400;
}
::v-deep td.statsLabel > a {
  color: #000;
}
h2.racetocosta-text {
  text-align: center;
  font-size: 12pt;
  margin-top: 0;
  margin-bottom: 0;
}
.splash {
  width: 100%;
}
.Splashheight {
  max-height: 600px;
  overflow: hidden;
}
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
}
::v-deep th {
  font-size: 1rem;
  padding: 0.75rem;
  border-top: 0px solid #dee2e6 !important;
  font-weight: 400;
  background-color: #42a7c6;
}
::v-deep td {
  font-size: 1rem;
  padding: 0.75rem;
  border-top: 0px solid #dee2e6 !important;
}
::v-deep thead > tr:first-child {
  background-color: white;
}
::v-deep tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.main > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  background-color: #01477b;
  font-weight: 400;
}
.PT > h2 {
  background-color: #3c974c !important;
}
.PT {
  color: #3d974c !important;
}
.PT:hover {
  color: #3c974c !important;
  text-decoration: none
}
.slider {
  margin-top: -550px;
}
.costaImg {
  margin: auto;
  display: block;
  width: 300px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.topSec {
  padding-top: 100px;
  padding-bottom: 50px;
}
::v-deep .year > .btn {
  width: 300px;
  -webkit-appearance: none;
  background-color: #015699;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  top: -50px;
  position: relative;
  left: -20px;
  text-align: left;
}
::v-deep .year > .btn::after {
  float: right;
  margin-top: 12px;
}
.dropdown {
  padding: 0 10px;
}
::v-deep .PTB > .btn {
  background-color: #3c974c;
}
.outerImg {
  height: 210px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* border-radius: 0.25rem; */
}
.miniWrap {
  position: absolute;
  bottom: 0px;
  width: 80%;
}
.homeNews {
  color: white;
  background: rgba(60, 151, 76, 1) !important;
  z-index: 99;
  font-size: 11pt;
  opacity: 0.8;
  height: 82px;

  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 14px 15px 17px;
  vertical-align: middle;
  text-transform: capitalize;

  margin-top: -42px;
}
.rolexTop {
  margin-top: -95px;
}
.viewFull {
  width: 300px;
  -webkit-appearance: none;
  background-color: #015699;
  border: none;
  border-radius: 0;
  padding: 2px;
  color: #fff;
  text-transform: uppercase;
  top: -93px;
  position: relative;
  left: 630px;
  text-align: left;
  font-size: 1rem;
}
.viewFull > a {
  color: white;
}
li {
  list-style: none;
}
.mobHide {
  display: block;
}
.mobShow {
  display: none;
}
.following-text {
  float: right;
  padding-right: 10px;
}
@media only screen and (max-width: 1024px) {
  .homeNews {
    font-size: 1em;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep h2.racetocosta-text {
    text-align: center;
    font-size: 12pt;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
  }
  .threeDropdown {
    display: block;
  }
  .miniWrap {
    margin-top: -104px;
  }
  .homeNews {
    height: 155px;
  }
  .mobHide {
    display: none;
  }
  ::v-deep .year > .btn {
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  .following-text {
    float: none;
    padding-right: 0px;
    display: block;
  }
  .custom-select {
    width: 300px;
    left: -10px;
    text-align: left;
    height: 42px;
    margin-bottom: 4px;
  }
}
@media only screen and (max-width: 520px) {
  ::v-deep td.hideMob {
    display: none;
  }
  ::v-deep th.hideMob {
    display: none;
  }
  ::v-deep .custom-select {
    width: 95%;
    left: 0;
    text-align: left;
    height: 42px;
    margin-bottom: 4px;
    margin-left: 10px;
  }
  ::v-deep th.RolexLable {
    display: none;
  }
  ::v-deep td.RolexLable {
    display: none;
  }
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .miniWrap {
    margin-top: -46px;
  }
  .homeNews {
    height: 52px;
  }
  ::v-deep .year > .btn {
    width: 100%;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: -43px;
    position: relative;
    left: 0;
    text-align: left;
  }
  .dropdown {
    display: block;
  }
  ::v-deep th {
    padding: 0.45rem;
  }
  ::v-deep .dropdown {
    padding: 0 10px;
    margin-bottom: 1em;
  }
  ::v-deep .topSec {
    padding-bottom: 0px;
  }
}
</style>
